.container {
  padding: 18px;
}

.container :global(.ant-row) {
  margin-bottom: 16px;
}

.innerContainer {
  padding: 4px;
}
