.innerCell {
  display: inline-flex;
  width: 100%;
}

.innerCell > div {
  flex-grow: 1;
  margin-left: 6px;
  margin-right: 6px;
  margin-top: 2px;
}

.infoCell {
  padding: 3px;
}

.cell,
.codeCell {
  font-family: monospace !important;
}

.alert {
  padding: 4px 8px;
}

.table {
  width: 100%;
  padding-top: 1em;
}

.table > thead > tr > th {
  text-align: left;
}

.table > tbody > tr > td {
  padding-right: 16px;
}

.disclaimer {
  font-style: italic;
  font-size: 9pt;
}
