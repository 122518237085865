.serviceActivation {
  font-weight: 100;
  font-size: 0.8em;
  margin-left: 4px;
}

.serviceGroup .emptyMenuItem:global(.ant-menu-item) :global(.ant-menu-title-content) {
  margin-left: 1.5rem;
}

:global(.light) .serviceGroup .emptyMenuItem:global(.ant-menu-item) :global(.ant-menu-title-content) {
  color: var(--gray-6);
}

:global(.light) .serviceGroup :global(.ant-menu-item-group-title) {
  color: var(--gray-13);
}

:global(.dark) .serviceGroup :global(.ant-menu-item-group-title) {
  color: var(--gray-6);
}

:global(.dark) .serviceGroup :global(.ant-menu-title-content) {
  color: var(--gray);
}

:global(.dark) .serviceGroup :global(.ant-menu-item-icon) {
  color: var(--gray);
}

:global(.ant-menu-vertical) .serviceGroup :global(.ant-menu-title-content) {
  display: inline-flex !important;
}
