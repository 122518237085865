@value sm-up from '../../../_shared/breakpoints.module.css';

.searchCriteriaFormGroup {
  padding-bottom: 0.5rem !important;
  display: flex;
  flex-direction: column;

  .starForRequired {
    color: var(--red);
    margin-right: 4px;
  }
}

.searchCriteriaFormGroup > :global(*.ant-form-item) {
  flex: 1;
  margin-bottom: 0.35rem;
}

.searchCriteriaFormGroup :global(.ant-form-item-control-input-content > *:last-child.ant-picker) {
  flex: 1;
  max-width: 200px;
}

.searchCriteriaFormGroup button.invisibleRemove {
  display: none;
}

.searchCriteriaFormGroup :global(.ant-form-item-control-input-content > *:last-child.ant-picker) {
  flex: 1;
  max-width: 200px;
}

@media screen and (sm-up) {
  .searchCriteriaFormGroup {
    flex-direction: row;
    padding-bottom: 0rem !important;
  }

  .searchCriteriaFormGroup > :global(*.ant-form-item) {
    margin-right: 0.5rem !important;
  }

  .searchCriteriaFormGroup button.invisibleRemove {
    display: inline-block;
    opacity: 0;
    cursor: auto;
  }
}
