.container {
  padding: 10px 8px 2px;
  border: 1px solid;
  border-radius: 8px;
}

.noFormContainer {
  padding-bottom: 8px;
  display: flex;
  gap: 8px;
  align-items: center;
  padding-left: 10px;
  padding-right: 10px;
}

:global(.light) .container {
  border-color: var(--gray-5);
  width: 100%;
  box-shadow: 0 0 12px 0 var(--gray-28);
}

:global(.dark) .container {
  border-color: var(--gray-10);
  color: white;
}

.container :global(.ant-form-item) {
  margin-bottom: 8px !important;
}

.container :global(.ant-row) {
  margin-bottom: 0;
}
