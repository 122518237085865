.filterTags {
  min-width: 200px;
}

.filterTagsDropdown {
  display: none !important;
}

.activeFiltersSelection {
  display: inline-flex;
  align-items: center;
  font-size: 14px;
  margin: 8px;
}

.labelContainer {
  padding-right: 10px;
}
