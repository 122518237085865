.errorBoundary {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.errorAlert {
  box-shadow:
    0 3px 6px -4px var(--gray-26),
    0 6px 16px 0 var(--gray-27),
    0 9px 28px 8px var(--gray-28);
}

.borderedMessage {
  border: 1px solid var(--gray-2);
}
