.sidebarButtonsContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: 12px;
  padding: 24px 24px 12px;
}

.expandedContainer {
  width: 256px;
}

.contractedContainer {
  flex-direction: column;
  row-gap: 8px;
}
