.jobActivation {
  color: var(--gray);
  font-weight: 100;
  font-size: 0.8em;
}

.jobNavDetails > * {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-left: 16px;
  color: var(--gray-20);
  font-size: 0.8em;
  line-height: 2.4;
}

:global(.dark) .jobNavDetails > * {
  color: var(--gray-6);
}

.jobNavDetails > * > :first-child {
  overflow: hidden;
  text-overflow: ellipsis;
}

.jobNavDetails > * > :last-child {
  color: var(--gray);
  font-weight: 100;
}
