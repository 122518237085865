:global(.mobile-sider) {
  width: 100% !important;
  margin: auto;
  border-right: none;
  max-width: 640px !important;
  height: 100vh;
}

:global(.web-sider) {
  z-index: 1;
}

:global(.web-side-menu) {
  overflow-y: auto;
  margin-bottom: 80px !important; /* height of .side-menu-bottom */
  padding-right: 8px;
}

:global(.sidebar-expanded) :global(.web-side-menu) {
  overflow-y: auto !important;
  overflow-x: hidden !important;
}

:global(.mobile-side-menu) {
  font-size: 16px !important;
}

:global(.side-menu-bottom) {
  position: fixed;
  bottom: 0;
  color: var(--gray-6);
}

.menuItemWithIcon {
  padding: 20px 0;
}

.globalSearchDesktopContainer {
  margin: 8px 0 12px 0;
  padding: 0 12px;
}

:global(.light .ant-menu-inline-collapsed-tooltip .ant-tooltip-inner) {
  background-color: var(--gray-2);
  color: var(--gray-13);
}

:global(.ant-menu-inline-collapsed-tooltip .ant-tooltip-inner) {
  position: relative;
  left: -4px;
}

:global(.ant-menu-inline-collapsed-tooltip .ant-tooltip-arrow) {
  display: none;
}

:global(.web-sider .ant-menu.ant-menu-inline-collapsed) {
  overflow: visible;
}

/* shove icons to the centre of the sidebar when the menu is collapsed */
:global(.web-sider .ant-menu.ant-menu-inline-collapsed) :global(.ant-menu-submenu-title) {
  padding-inline: calc(50% - 20px);
}

:global(.web-sider .ant-menu.ant-menu-inline-collapsed > .ant-menu-submenu > .ant-menu-submenu-title:hover) {
  overflow: visible;
  position: relative;
}

/**
    modifications to tooltips when the sidebar is collapsed
 */
:global(.web-sider .ant-menu.ant-menu-inline-collapsed > .ant-menu-submenu > .ant-menu-submenu-title .anticon + span) {
  transition: none;
}

:global(.web-sider .ant-menu.ant-menu-inline-collapsed > .ant-menu-submenu > .ant-menu-submenu-title:hover .anticon + span) {
  opacity: 1;
  box-shadow:
    0 6px 16px 0 var(--gray-27),
    0 3px 6px -4px var(--gray-26),
    0 9px 28px 8px var(--gray-28); /*as submenus*/
  padding: 0 0.75em;
  position: absolute;
  top: -3em;
  left: 5em;
}

:global(.light .web-sider .ant-menu.ant-menu-inline-collapsed > .ant-menu-submenu > .ant-menu-submenu-title:hover .anticon + span) {
  background-color: var(--gray-2);
  color: var(--gray-13);
}

:global(.dark .web-sider .ant-menu.ant-menu-inline-collapsed > .ant-menu-submenu > .ant-menu-submenu-title:hover .anticon + span) {
  background-color: var(--gray-9); /*as tooltip*/
  color: var(--gray-6);
  box-shadow:
    0 6px 16px 0 var(--gray-27),
    0 3px 6px -4px var(--gray-26),
    0 9px 28px 8px var(--gray-28); /*as submenus*/
}

:global(.web-sider .ant-menu.ant-menu-inline-collapsed > .ant-menu-submenu > .ant-menu-submenu-title:hover button) {
  display: none;
}

/**
    end of modifications to tooltips when the sidebar is collapsed
 */

:global(.ant-menu-dark .ant-menu-inline.ant-menu-sub) {
  background-color: unset !important;
}

/*:global(.dark .ant-layout-sider-children) {*/
/*    font-size: 14px;*/
/*    line-height: 0;*/
/*    text-align: left;*/
/*    background: var(--gray-12);*/
/*}*/
