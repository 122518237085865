.positionRelative {
  position: relative;
}

.backgroundTimePicker {
  visibility: hidden;
  z-index: -1;
  width: calc(100% - 51px);
}

.timePickerContainer {
  position: absolute !important;
  width: calc(100% - 5px);
  left: 0px;
  top: 0px;
  display: flex;
}

.timePicker {
  flex-grow: 1;
  border: 1px solid #d9d9d9; /*--gray-5 in zoe*/
  border-radius: 8px;

  :global(input) {
    border-radius: 8px;
  }

  &.disabled {
    background-color: rgb(0 0 0 / 4%);
  }

  &.invalid {
    border-color: #ff4d4f !important;
  }
}

:global(.dark) {
  .timePicker {
    border-color: var(--gray-9);
  }
}

.inputMask {
  height: 100%;
  width: 100%;
  padding-left: 10px;
  border: none !important;
  outline: none;
  box-sizing: border-box;
}

.exclamationMarkIcon {
  position: absolute;
  right: 5%;
  top: 30%;
  color: #ff4d4f !important;
}
