@font-face {
  font-family: 'Moderat';
  font-style: normal;
  font-weight: 100;
  src:
    local(''),
    url('./res/Moderat-Thin.woff2') format('woff2'),
    /* Chrome 26+, Opera 23+, Firefox 39+ */ url('./res/Moderat-Thin.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

@font-face {
  font-family: 'Moderat';
  font-style: italic;
  font-weight: 100;
  src:
    local(''),
    url('./res/Moderat-Thin-Italic.woff2') format('woff2'),
    /* Chrome 26+, Opera 23+, Firefox 39+ */ url('./res/Moderat-Thin-Italic.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

@font-face {
  font-family: 'Moderat';
  font-style: normal;
  font-weight: 200;
  src:
    local(''),
    url('./res/Moderat-Light.woff2') format('woff2'),
    /* Chrome 26+, Opera 23+, Firefox 39+ */ url('./res/Moderat-Light.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

@font-face {
  font-family: 'Moderat';
  font-style: italic;
  font-weight: 200;
  src:
    local(''),
    url('./res/Moderat-Light-Italic.woff2') format('woff2'),
    /* Chrome 26+, Opera 23+, Firefox 39+ */ url('./res/Moderat-Light-Italic.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

@font-face {
  font-family: 'Moderat';
  font-style: normal;
  font-weight: 300;
  src:
    local(''),
    url('./res/Moderat-Light.woff2') format('woff2'),
    /* Chrome 26+, Opera 23+, Firefox 39+ */ url('./res/Moderat-Light.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

@font-face {
  font-family: 'Moderat';
  font-style: italic;
  font-weight: 300;
  src:
    local(''),
    url('./res/Moderat-Light-Italic.woff2') format('woff2'),
    /* Chrome 26+, Opera 23+, Firefox 39+ */ url('./res/Moderat-Light-Italic.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

@font-face {
  font-family: 'Moderat';
  font-style: normal;
  font-weight: 400;
  src:
    local(''),
    url('./res/Moderat-Regular.woff2') format('woff2'),
    /* Chrome 26+, Opera 23+, Firefox 39+ */ url('./res/Moderat-Regular.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

@font-face {
  font-family: 'Moderat';
  font-style: italic;
  font-weight: 400;
  src:
    local(''),
    url('./res/Moderat-Regular-Italic.woff2') format('woff2'),
    /* Chrome 26+, Opera 23+, Firefox 39+ */ url('./res/Moderat-Regular-Italic.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

@font-face {
  font-family: 'Moderat';
  font-style: normal;
  font-weight: 500;
  src:
    local(''),
    url('./res/Moderat-Medium.woff2') format('woff2'),
    /* Chrome 26+, Opera 23+, Firefox 39+ */ url('./res/Moderat-Medium.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

@font-face {
  font-family: 'Moderat';
  font-style: italic;
  font-weight: 500;
  src:
    local(''),
    url('./res/Moderat-Medium-Italic.woff2') format('woff2'),
    /* Chrome 26+, Opera 23+, Firefox 39+ */ url('./res/Moderat-Medium-Italic.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

@font-face {
  font-family: 'Moderat';
  font-style: normal;
  font-weight: 600;
  src:
    local(''),
    url('./res/Moderat-Bold.woff2') format('woff2'),
    /* Chrome 26+, Opera 23+, Firefox 39+ */ url('./res/Moderat-Bold.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

@font-face {
  font-family: 'Moderat';
  font-style: italic;
  font-weight: 600;
  src:
    local(''),
    url('./res/Moderat-Bold-Italic.woff2') format('woff2'),
    /* Chrome 26+, Opera 23+, Firefox 39+ */ url('./res/Moderat-Bold-Italic.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

@font-face {
  font-family: 'Moderat';
  font-style: normal;
  font-weight: 700;
  src:
    local(''),
    url('./res/Moderat-Bold.woff2') format('woff2'),
    /* Chrome 26+, Opera 23+, Firefox 39+ */ url('./res/Moderat-Bold.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

@font-face {
  font-family: 'Moderat';
  font-style: italic;
  font-weight: 700;
  src:
    local(''),
    url('./res/Moderat-Bold-Italic.woff2') format('woff2'),
    /* Chrome 26+, Opera 23+, Firefox 39+ */ url('./res/Moderat-Bold-Italic.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

@font-face {
  font-family: 'Moderat';
  font-style: normal;
  font-weight: 900;
  src:
    local(''),
    url('./res/Moderat-Black.woff2') format('woff2'),
    /* Chrome 26+, Opera 23+, Firefox 39+ */ url('./res/Moderat-Black.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

@font-face {
  font-family: 'Moderat';
  font-style: italic;
  font-weight: 900;
  src:
    local(''),
    url('./res/Moderat-Black.woff2') format('woff2'),
    /* Chrome 26+, Opera 23+, Firefox 39+ */ url('./res/Moderat-Black.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* lseg */

@font-face {
  font-family: 'Proxima Nova';
  font-style: normal;
  font-weight: 400;
  src:
    local(''),
    url('./res/proximanova-regular.woff') format('woff');
}

@font-face {
  font-family: 'Proxima Nova';
  font-style: normal;
  font-weight: 700;
  src:
    local(''),
    url('./res/proximanova-semibold.woff') format('woff');
}

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
a,
abbr,
acronym,
address,
big,
cite,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
input,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  font-family: 'Proxima Nova', sans-serif !important;
}

code {
  font-family: monospace;
  font-size: 12px;
}
