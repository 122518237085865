.smallText {
  font-size: 10px;
  line-height: 12px;
}

.topPadded {
  line-height: 24px;
}

.countryList {
  flex-grow: 1;
  margin-top: 5px;
  text-align: left;
}
