.container {
  height: 100%;
  border: 0;
}

.frame {
  width: 100%;
  height: 100%;
  border: 0;
}

.hiddenFrame {
  display: none;
}

.visibleFrame {
  display: block;
}
