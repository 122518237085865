:global(.dark) .container {
  /* matches class .markdown-body in github-markdown-css/github-markdown-dark.css */
  background-color: #0d1117;
}

.searchFormItem {
  margin-bottom: 0px;
}

.searchBackButton {
  display: flex;
  align-items: center;
  padding: 0px;
}

.preformatted,
.preformatted span,
.preformatted mark {
  font-family: monospace !important;
  white-space: pre-wrap;
}

.previewTag {
  font-family: monospace !important;
  font-size: 10px;
  opacity: 0.3;
}

.clearHighlightsButton {
  background-color: var(--gray-4) !important;
  :global(.dark) & {
    background-color: var(--gray-10) !important;
  }
}
