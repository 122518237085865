.verticalSpace {
  height: 100%;
  justify-content: center;
}

.horizontalSpace {
  width: 100%;
  justify-content: space-between;
}

.clearButton {
  padding: 0 !important;
  border: 0 !important;
  height: unset !important;
  line-height: unset !important;
}
