.filterDropdown {
  padding: 8px;
  display: flex;
  flex-direction: column;
}

.extraFilters {
  width: 120px !important;
  margin-bottom: 5px !important;
}

.searchInput {
  width: 185px !important;
  margin-bottom: 8px !important;
  display: block !important;
}

.smallButton {
  width: 90px;
}
