.customTooltip {
  display: flex;
  flex-direction: column;
  background-color: var(--gray-34);
}

.tooltipHeader {
  padding: 10px;
  text-align: center;
  font-weight: bold;
  background-color: var(--gray-2);
  border-bottom: 1px solid var(--gray-5);
}

.tooltipList {
  margin: 0;
  padding: 10px;
  list-style: none;
}

.exchangeSelector {
  width: 250px;
  margin: 10px;
}

.tabPaneContent {
  display: flex;
  position: relative;
}

.tabPaneBarAreaTable {
  position: absolute;
  width: 400px;
  right: 10px;
  padding: 10px;
  box-shadow:
    0 3px 6px -4px var(--gray-26),
    0 6px 16px 0 var(--gray-27),
    0 9px 28px 8px var(--gray-28);
  background-color: var(--gray-6);
}

.tableAreaCloseButton {
  position: absolute;
  right: -10px;
  top: -10px;
  z-index: 1;
}

.breakdownTableTitle {
  background-color: var(--gray-2);
  text-align: center;
  font-weight: bold;
  padding: 10px;
}

.secondYAxisLine {
  transform: translate(60px, 0px);
}

.graphControlHeader {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.graphControlProductSelect {
  margin-left: 5px;
  width: 150px;
}
