.preferencesContainer {
  background-color: var(--gray-2);
  padding: 10px;
  border: 1px solid var(--gray-4);
  border-radius: 5px;
}

:global(.dark) .preferencesContainer {
  background-color: var(--gray-12);
  border-color: var(--gray-10);
}

.preferencesContainer :global(strong) {
  font-size: 16px;
}

.preferenceItem {
  text-align: right;
  margin-bottom: 8px;

  &:last-child {
    margin-bottom: 0;
  }
}
