@value md-up from '../../../_shared/breakpoints.module.css';

.emptyPlaceholder {
  font-style: italic;
  text-align: center;
  padding: 15px;
  width: 100%;
}

.itemContainer {
  display: flex;
}

.iconContainer {
  padding-right: 6px;
}

.menuTitle {
  font-weight: bold;
}

.searchTypeDropdown {
  width: 100%;
  :global(.dark) & {
    border-radius: 2px;
    &:hover {
      color: var(--gray-6);
    }
  }
}

.buttonText {
  text-align: left;
  width: calc(100% - 15px);
}

@media screen and (md-up) {
  .searchTypeDropdown {
    max-width: 400px;
  }
}

.smallDivider {
  margin-top: 16px;
  margin-bottom: 16px;
}

.smallSpinner {
  line-height: 20px;
}
