@value md-up, lg-up from '../../../../../../../_shared/breakpoints.module.css';

.header {
  align-items: flex-start;
  flex-direction: column-reverse;
  width: 100%;
}

.tableHeaderTools {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}

.tableHeaderTools > :global(.ant-space-item) {
  margin-bottom: 8px;
}

.subTablesContainer {
  padding: 20px 50px;
}

.subTable {
  margin-bottom: 20px;
}

.subTableTitle {
  font-size: 16px !important;
}

@media screen and (md-up) {
  .tableHeaderTools {
    flex-wrap: nowrap;
    justify-content: flex-end;
  }
}

@media screen and (lg-up) {
  .header {
    flex-direction: row;
  }

  .header > :global(.ant-space-item) {
    flex: 1;
  }

  .tableHeaderTools > :global(.ant-space-item) {
    margin-bottom: 0;
  }
}

.pagingDataTable {
  font-weight: 400;
}

.pagingDataTable :global(.ant-table-container) {
  overflow-x: scroll;
}

.dataCombo {
  width: 150px;
}

:global(.ant-pagination.ant-pagination-disabled) {
  & button:global(.ant-pagination-item-link),
  & li:global(.ant-pagination-item) {
    background-color: var(--gray-13);
    border-color: var(--gray-6);
  }
  & li {
    opacity: 0.2;
  }
  & :global(.ant-pagination-item) a {
    color: var(--gray-6);
  }
}
