.advancedSearchModal {
  font-weight: 400;
}

.resultTable {
  padding-top: 1rem;
}

.resultTableHelpText {
  color: var(--gray-22);
  display: block;
  font-size: 14px;
  line-height: 1.5715;
  padding-top: 0.5rem;
}

.tag {
  margin-bottom: 10px;
}
