.animatedKeyContainer {
  width: 100%;
  animation: animate 0.2s linear;
}

.animatedKeyContainer :global(.ant-divider-horizontal) {
  margin: 0 !important;
}

.animatedKeyContainerTitle {
  font-size: 16px !important;
}

@keyframes animate {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}
