.headerContainer {
  margin: 16px 16px 8px;
}

.headerText {
  white-space: nowrap;
  display: block;
  font-size: 21px;
  line-height: 21px;
  margin-top: 0 !important;
  margin-bottom: 0 !important;
  color: inherit !important;
  overflow: hidden;
}

.subHeaderText {
  font-size: 11px;
  line-height: 11px;
  margin: 0;
  margin-bottom: 0.5em !important;
  padding: 0;
  color: inherit !important;
  overflow: hidden;
}
