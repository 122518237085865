.globalSearchResultGroupTitle {
  text-transform: capitalize;
}

.globalSearch {
  width: 100%;
  border-radius: 8px;
  box-shadow: 0 4px 24px var(--gray-31);
  border-right: 0;
}

.globalSearch :global(.ant-select-selector) {
  border: none !important;
}

.globalSearch :global(.ant-input-group-addon),
.globalSearch :global(.ant-select-selector) {
  background-color: transparent !important;
}

.globalSearch :global(input) {
  border-top-left-radius: 10px !important;
  border-bottom-left-radius: 10px !important;
}

.globalSearch :global(.ant-input-group-addon),
.globalSearch :global(button) {
  border-top-right-radius: 10px !important;
  border-bottom-right-radius: 10px !important;
}

.globalSearch :global(input),
.globalSearch :global(input:active),
.globalSearch :global(input:hover),
.globalSearch :global(button),
.globalSearch :global(button:active),
.globalSearch :global(button:hover) {
  border: none !important;
}

.globalSearchPopup {
  width: 500px;
}

.collapsedSearchContainer {
  text-align: center;
}

:global(.light) .searchContainer {
  box-shadow:
    0 1px 2px 0 rgba(0, 0, 0, 0.03),
    0 1px 6px -1px rgba(0, 0, 0, 0.02),
    0 2px 4px 0 rgba(0, 0, 0, 0.02);
}

:global(.dark) .searchContainer :last-child {
  border-top-right-radius: 8px !important;
  border-bottom-right-radius: 8px !important;
  border-left: none !important;
}

:global(.light) .searchContainer :last-child {
  border-left: none !important;
}

.collapsedSearch {
  text-align: center;
}

.collapsedSearch :global(button) {
  border: 0 !important;
}
