.isLarge :global(.ant-modal-body) {
  font-size: 0.8em;
  padding: 0 !important;
}

.isLarge :global(.ant-modal-footer) {
  margin-top: 1em !important;
}

.isLarge .bodyContent {
  max-height: 70vh;
  overflow-y: auto;
  padding: 2em;
}
