.dpActivityContainer :global(.ant-collapse-content) {
  border-top: none;
}

.dpActivityContainer :global(.ant-collapse-content-box) {
  padding: 0 !important;
}

.dpActivityContainer :global(.ant-table-pagination.ant-pagination) {
  margin: 16px;
}

.dpActivityContainer :global(.ant-table-cell-fix-right::after) {
  box-shadow: none;
}

.dpActivityContainer :global(.ant-row) {
  margin-bottom: 16px;
}

.dpActivityContainer :global(.ant-alert) {
  border-left: none;
  border-right: none;
  border-top: none;
  border-radius: 0 !important;
}

.infoText {
  text-decoration: underline dotted;
  color: var(--blue-7);
  margin-right: 3px;
  cursor: pointer;
}

.tableActions {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.statusFailed {
  color: var(--red);
}

.errorStatement {
  max-width: 500px;
  max-height: 400px;
  overflow-x: hidden;
  overflow-y: scroll;
}

.maxWidth {
  width: 500px;
  max-width: 500px;
}

.headerButton {
  height: 20px;
  padding: 0;
}

.ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
