.rightGraph {
  float: right;
  z-index: 9999;
}

.drawerWrapper {
  position: relative;
  height: 600px;
  overflow: hidden;
}
