.notificationsMenu :global(.ant-list-item > .ant-menu-title-content) {
  line-height: unset !important;
  height: unset !important;
}

.notificationsMenu :global(.ant-list-items > .ant-list-item) {
  line-height: unset !important;
  height: unset !important;
  margin-bottom: 8px;
  padding-top: 4px;
  padding-bottom: 4px;
}

.notificationsMenu :global(.ant-list-items .ant-list-item:not(:last-child)) {
  margin-bottom: 8px;
}

.notificationsMenu :global(.ant-list-items > .ant-list-item div) {
  line-height: 20px;
  white-space: initial;
}

.notificationsMenu :global(.ant-space-item) {
  line-height: unset;
  height: unset;
}

.notificationsMenu .notificationsHeader {
  display: flex;
  flex-direction: row;
  justify-content: right;
  position: sticky;
  z-index: 1010;
  top: 0;
  padding-bottom: 5px;
}

:global(.dark) :global(.web-side-menu) .notificationsMenu .notificationsHeader {
  background-color: var(--gray-12);
  z-index: 2;
}

:global(.light) :global(.web-side-menu) .notificationsMenu .notificationsHeader {
  background-color: var(--gray-2);
  z-index: 2;
}

:global(.light) .notificationsMenu .notificationsHeader {
  background-color: var(--gray-1);
}

.notificationsMenu .clearAllIcon,
.notificationsMenu .clearIcon {
  font-size: 15px !important;
}

.notificationsMenu .clearAllButton {
  border: 0px;
  box-shadow: none;
  background-color: transparent;
}

:global(.light) .notificationsMenu :global(.ant-list),
:global(.dark) .notificationsMenu :global(.ant-list) {
  background-color: transparent;
  border: 0 none;
}

:global(.light) .notificationsMenu :global(.ant-list-item),
:global(.dark) .notificationsMenu :global(.ant-list-item) {
  align-items: baseline;
  cursor: pointer;
  padding-right: 0;
}

:global(.light) .notificationsMenu :global(.ant-list-item) {
  & :global(.ant-list-item-meta):hover :global(.ant-list-item-meta-description) {
    color: var(--gray-21) !important;
  }
}

:global(.dark) .notificationsMenu :global(.ant-list-item) {
  & :global(.ant-list-item-meta):hover :global(.ant-list-item-meta-description) {
    color: var(--gray-33) !important;
  }
}

:global(.light) .notificationsMenu :global(.ant-list-item-meta-avatar span),
:global(.dark) .notificationsMenu :global(.ant-list-item-meta-avatar span) {
  margin-right: 4px !important; /* override style applied directly to element */
}

:global(.light) .notificationsMenu :global(.ant-list-item-meta-avatar svg),
:global(.dark) .notificationsMenu :global(.ant-list-item-meta-avatar svg) {
  margin-top: 2px;
}

:global(.light) .notificationsMenu :global(.ant-list-item-action),
:global(.dark) .notificationsMenu :global(.ant-list-item-action) {
  margin-left: 2px;
}

:global(.light) .notificationsMenu :global(.ant-list-item-action > li),
:global(.dark) .notificationsMenu :global(.ant-list-item-action > li) {
  margin-right: 0;
  padding-right: 0;
}

.notificationItemDisabled {
  cursor: default !important;
}

.header {
  font-weight: bold;
  font-size: 11pt;
}

.standard {
  font-size: 10pt;
}

.popupContainer {
  width: 240px;
  /* popup doesn't have a parent container we can use */
  border: 0 !important;
  /* Fixes bug in Firefox where popup content scrolled horizontaly */
  margin-right: 15px;
}

.collapsedButton {
  margin-top: 10px;
  display: flex;
  justify-content: center;
  button :global(.ant-btn-icon) span {
    margin-left: 5px;
  }
}

.notificationDate {
  margin-top: 4px;
  text-align: left;
  font-size: 8pt;
}

.noNotifications {
  text-align: center;
  margin-top: 24px;
  margin-bottom: 24px;
  width: 250px;
}

:global(.dark) .noNotifications {
  color: var(--gray-6);
}

.notificationPopup :global(.ant-popover-inner) {
  padding-right: 0;
}
