.timePickerWidth {
  width: 201px;
}

.arrowLineHeight {
  line-height: 28px !important;
  margin-top: 4px;
}

.dateTimePickerSpace {
  position: relative;
  align-items: stretch;
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.timePickers {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}
