@value sm-down, md-down from "./breakpoints.module.css";

.pageHeader {
  display: flex;
}

.pageHeader > h1 {
  flex: 1;
  font-size: 32px;
}

.pageHeaderRight {
  margin-bottom: 5px;
}

.pageHeaderRight:not(:last-child) {
  margin-right: 8px;
}

@media (sm-down) {
  .pageHeader > h1 {
    font-size: 28px !important;
  }
}
