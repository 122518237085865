:root {
  /* "Neutral Color Palette" from https://ant.design/docs/spec/colors */
  --gray-1: white;
  --gray-2: #fafafa;
  --gray-4: #f0f0f0;
  --gray-5: #d9d9d9;
  --gray-6: #bfbfbf;
  --gray-7: #8c8c8c;
  --gray-9: #434343;
  --gray-10: #262626;
  --gray-12: #141414;
  --gray-13: black;

  --gray: #737373;

  /* custom blacks with alpha */
  --gray-20: #000000e0;
  --gray-21: #000000a6;
  --gray-22: #0000007a;
  --gray-23: #00000052;
  --gray-24: #00000040;
  --gray-25: #00000033;
  --gray-26: #0000001f;
  --gray-27: #00000014;
  --gray-28: #0000000d;

  /* custom white with alpha */
  --gray-30: #ffffff1a;
  --gray-31: #ffffff20;
  --gray-32: #ffffff4d;
  --gray-33: #ffffff99;
  --gray-34: #ffffffd9;

  /* blue-(1-7) generated from https://ant.design/docs/spec/colors starting from custom blue-6 */
  --blue-1: #e6f7ff;
  --blue-2: #a3dfff;
  --blue-3: #7accff;
  --blue-4: #4fb1f7;
  /* primary (but hover, selected ...)  */
  --blue-5: #2692eb;
  /* primary */
  --blue-6: #0074df;
  --blue-7: #0069ca;

  /* custom blue */
  --blue-10: #000843;
  --blue-5--alpha-b3: #2692ebb3;
  --blue-5--alpha-33: #2692eb33;

  /* "Calendula Gold" from https://ant.design/docs/spec/colors*/
  --gold-1: #fffbe6;
  --gold-5: #ffc53d;
  --gold-6: #faad14;
  --gold-7: #d48806;
  --gold-8: #ad6800;

  --red: #ff0000;

  /* "Dust Red" from https://ant.design/docs/spec/colors*/
  --red-5: #ff4d4f;
  --red-6: #f5222d;
  --red-8: #a8071a;

  /* "Lime" from https://ant.design/docs/spec/colors*/
  --lime-6: #a0d911;
  --lime-7: #7cb305;
  --lime-8: #5b8c00;
  --lime-10: #254000;

  /* "Polar Green" from https://ant.design/docs/spec/colors*/
  --green-6: #52c41a;
}

:root,
[data-amplify-theme] {
  /*blue-5*/
  --amplify-components-button-primary-background-color: #2692eb;
  /*blue-8*/
  --amplify-components-button-primary-hover-background-color: #0050b8;
  /*blue-1*/
  --amplify-components-button-hover-background-color: #e6f7ff;
  /*blue-1*/
  --amplify-components-button-link-hover-background-color: #e6f7ff;
  --amplify-components-button-link-hover-color: #000;
  --amplify-components-button-link-color: #000;
}
