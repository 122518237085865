.navLabel {
  display: flex;
  justify-content: space-between;
  align-items: center;
  column-gap: 2px;
}

.navLabel > a {
  overflow: hidden;
  text-overflow: ellipsis;
  flex-grow: 1;
}

.navLabel :global(.ant-btn-dangerous) {
  min-width: 32px;
}

.subtitledNavLabel {
  line-height: 20px;
}

.subtitledNavLabel > div:last-child {
  opacity: 0.5;
  overflow: hidden;
  text-overflow: ellipsis;
}

.disabledNavItem {
  opacity: 0.75;
}
