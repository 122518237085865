.applyButtonContainer {
  margin-top: 8px;
}

.downloadButtonContainer {
  margin-top: 8px;
  margin-left: 8px;
}

.tagWrapper {
  border: 1px solid var(--gray-5);
  border-radius: 2px;
  padding: 3px 4px;
}

.tagWrapper:hover {
  border-color: var(--blue-4);
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.paddedTag {
  padding: 2px 6px;
  cursor: default;
}

.addTag {
  background: var(--gray-6);
  border-style: dashed;
  cursor: pointer;
}

.note {
  font-style: italic;
  font-size: 13px;
  margin-left: 3px;
  margin-right: 10px;
  user-select: none;
}

.interval div:global(.ant-form-item-label.ant-form-item-label-wrap) {
  text-align: left;
}
